@font-face {
	font-family: 'vafle-normal';
	src: url('../fonts/vafle_regular_vb.eot');
	src: url('../fonts/vafle_regular_vb.eot') format('embedded-opentype'),
	url('../fonts/vafle_regular_vb.woff2') format('woff2'),
	url('../fonts/vafle_regular_vb.woff') format('woff'),
	url('../fonts/vafle_regular_vb.ttf') format('truetype'),
	url('../fonts/vafle_regular_vb.svg#VafleVUTRegular') format('svg');
}

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";

// 2. Include any default variable overrides here
$primary: #1676d0 !default;
$body-secondary-color-dark: rgb(110, 110, 110, 0.5);

$font-family-sans-serif: 'Open Sans', Arial, Helvetica, sans-serif;
$font-size-base: 0.9rem;
$input-font-size: 1rem;

$grid-gutter-width: 0;
$container-padding-x: 0;

$border-radius: 0rem !default;
$border-radius-sm: 0rem !default;
$border-radius-lg: 0rem !default;
$border-radius-xl: 0rem !default;
$border-radius-xxl: 0rem !default;
$border-radius-pill: 0rem !default;

$alert-margin-bottom: 1.5rem !default;

$h2-font-size: 2rem;
$h3-font-size: 1.75rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;

$link-color-dark: #68CDFE;
$body-tertiary-bg: #f8f9fa;
$body-tertiary-bg-dark: #2b3035;
//--bs-link-hover-color: #8bb9fe;

// 3. Include remainder of required Bootstrap stylesheets
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

// 4. Include any default map overrides here


// 5. Include remainder of required parts
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// 6. Optionally include any other parts as needed
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/spinners";

@import "bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "bootstrap/scss/utilities/api";

// 8. Add additional custom code here
*,
*::before,
*::after {
	box-sizing: border-box;
}

:root,
[data-bs-theme="light"] {
	--#{$prefix}-hover-bg: #dce1e8;
	--bs-border-gr: rgb(128, 128, 128, 0.1);
	--bs-body-bg-dots: #f0f5f4;
}
[data-bs-theme="light"]{
	--bs-info-bg-subtle:#bcdefd;
	--bs-info-border-subtle:#5aafff;
}

[data-bs-theme=dark] {
	--bs-hover-bg: #000;
	--bs-border-gr: #4e4d4d90;
	--bs-body-bg-dots: #2b2c2d;
	--bs-secondary-rgb: 167, 172, 177;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
	font-family: vafle-normal, 'Open Sans', Arial, Helvetica, sans-serif;
}

@import "theme";

/*vlastni tridy*/
main {
	min-height: 85vh;
}

footer {
	height: 15vh;
	position: relative;
}

.tr-2 {
	position: fixed;
	top: 0;
	right: 0px;
	z-index: -2;
}

.tr-3 {
	position: fixed;
	top: 0;
	right: 0px;
	z-index: -2;
}

.tr-4 {
	position: fixed;
	bottom: 0;
	left: 0px;
	z-index: -2;
	height: 12vh;
	width: 60vw;
	border-right: 62vw solid transparent;
	border-bottom: 12vh solid var(--bs-border-gr);
}

.tr-5 {
	position: fixed;
	bottom: 0;
	left: 0px;
	z-index: -2;
	height: 10vh;
	width: 30vw;
	border-right: 30vw solid transparent;
	border-bottom: 10vh solid var(--bs-border-gr);
}


body {
	overflow-y: scroll;
	position: relative;
	min-height: 100vh;
}

.container > .row {
	justify-content: center;
}

p:last-of-type {
	margin-bottom: 0px;
}

.form-group .btn:only-child {
	width: 100%;
}

.form-group {
	.input-group {
		button {
			width: auto !important;
			margin-bottom: 0;
		}
	}
}

.btn {
	line-height: 1.5rem;
}

.form-block.footer {
	border-top: 1px solid rgb(128, 128, 128, 0.4)
}

.form-header {
	text-align: center;
	font-size: 0.875em;
	padding: 1.5rem 0 0 0;
}

.form-block.footer {
	font-size: 0.875em;
	align-items: flex-end;
}

.form-block.footer.sticky-bottom {
	background-color: rgba(var(--bs-tertiary-bg-rgb), 0.7);
}

/*.form-block.footer.sticky-bottom .btn-outline-secondary {background-color: rgba(var(--bs-tertiary-bg-rgb));}
.form-block.footer.sticky-bottom .btn-outline-secondary:hover {background-color: rgba(var(--bs-btn-hover-bg));}*/
.form.form-wide {
	max-width: 600px;
}

.form-check input {
	border: 1px solid grey;
}

input.form-control {
	border-color: grey;
}

.input-group-text {
	border-color: grey;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
	cursor: default;
	opacity: 1;
}

.list-item{
	display: flex;
	flex-wrap: wrap;
	.list-value{
		//font-weight: norm;
		//font-style: italic;
		width: 100%;
		display: block;
	}
	.list-label{
		margin-right: 0.25rem;
	}
	span:only-of-type{
		width: auto;
	}
}

.avatar {
	color: var(--bs-body-color);
	border-radius: 50%;
	height: 45px;
	width: 45px;
	background-color: var(--bs-body-bg);
	padding-top: 11px;
	text-align: center;
	margin: auto;
	display: block;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
	font-weight: bold;
}

.block-border-top {
	border-top: 1px solid rgb(128, 128, 128, 0.4)
}

.header-icon {
	transform: scale(0.8);
	transition-duration: 500ms;
	text-align: center;
}

.header-icon:hover {
	transform: scale(1.1);
	transition-duration: 500ms;
}

.alert-icon {
	padding-right: 1rem
}

.alert-danger .alert-icon {
	border-right: 2px solid var(--bs-danger-text-emphasis);
}

.alert-warning .alert-icon {
	border-right: 2px solid var(--bs-warning-text-emphasis);
}

.alert-secondary .alert-icon {
	border-right: 2px solid var(--bs-secondary-text-emphasis);
}

.alert-success .alert-icon {
	border-right: 2px solid var(--bs-success-text-emphasis);
}

.alert-text {
	padding-left: 1rem;
}

.alert {
	margin: 0px;
}

.alert .alert-icon svg {
	height: 100%;
}

/*.alert {margin:0px;container-type: size;height:fit-content;}
@container (width > 20px) {
  .alert.container .alert-icon {
    border-right:50px solid black;
  }
}*/

.user:hover {
	background-color: var(--bs-body-bg);
	color: var(--bs-body-color);
}

.user:hover .avatar {
	background-color: var(--bs-hover-bg);
}


.block-footer, .block-header {
	display: flex;
	align-items: center;
	text-align: center;
}

.block-footer::before,
.block-footer::after {
	content: '';
	flex: 1;
	border-bottom: 1px solid rgb(128, 128, 128, 0.4);
}

.block-header::before,
.block-header::after {
	content: '';
	flex: 1;
}

.block-footer span, .block-header span {
	padding: 0px 12px;
	font-size: 13px;
}

.block-header span {
	font-size: 13px;
}

.user .close-btn {
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s 0.5s, opacity 0.5s linear;
}

.user:hover .close-btn {
	visibility: visible;
	opacity: 1;
	transition: opacity 0.5s linear;
}

.accordion-body {
	padding: 0px;
}

.accordion-body > div:first-of-type {
	padding: 12px 12px 9px 12px;
}

.accordion-body > div:hover {
	background-color: var(--bs-hover-bg);
}

.accordion-body > div:not(:last-of-type) {
	border-bottom: 1px solid rgb(128, 128, 128, 0.4);
}

.accordion-body > div {
	position: relative;
}

.accordion-body > div > img {
	margin-right: 6px;
	vertical-align: text-bottom;
	height: 20px;
}

.site-footer {
	align-items: flex-end;
	width: 100%;
	padding: 24px;
	bottom: 20px;

	@supports(padding: max(0px)) {
		padding-left: max(24px, env(safe-area-inset-left, 0));
		padding-right: max(24px, env(safe-area-inset-right, 0));
		padding-bottom: env(safe-area-inset-bottom, 0);
	}
}

.subheading {
	text-align: center;
	font-size: 0.875em;
}

label + span {
	float: right;
}

.login-plain {
	font-weight: bold;
}

body {
	// background-color: var(--bs-body-bg-dots);
	background-repeat: repeat;
	background-size: 14px;
}

@media (min-width: 576px) {

	body {
		// background-color: var(--bs-body-bg-dots);
		background-image: url('../images/svg/dots-pattern.svg');
		background-repeat: repeat;
		background-size: 14px;
	}
	[data-bs-theme="dark"] body {
		background-image: url('../images/svg/dots-pattern-darker.svg');
	}
	main {
		padding: 1.5rem;
		@supports(padding: max(0px)) {
			padding-left: max(1.5rem, env(safe-area-inset-left, 0));
			padding-right: max(1.5rem, env(safe-area-inset-right, 0));
		}
	}

	#collapseSwitch {
		position: fixed;
		right: 24px;
		top: 55px;
		background-color: var(--bs-body-bg);
		margin-top: 14px;
		border: 1px solid gray;

		.form-switch.form-check {
			padding: 0.75rem 0.75rem 0.75rem 5.25rem;
		}
	}
	.form-check.form-switch {
		padding-left: 4.5em;
		line-height: 2rem;
		margin-bottom: 0px;
	}
	.form-check.form-switch .form-check-input {
		width: 3em;
		margin-left: -4.5em;
		height: 1.5rem;
		cursor: pointer;
	}

	.logo {
		position: fixed;
		top: 24px;
		left: 24px;
		//z-index: -1;

		@supports(padding: max(0px)) {
			left: max(24px, env(safe-area-inset-left));
		}
	}

	.tr-2 {
		height: 60vh;
		width: 80vw;
		border-right: 78vw solid var(--bs-border-gr);
		border-bottom: 60vh solid transparent;
	}

	.tr-3 {
		height: 90vh;
		width: 20vw;
		border-right: 20vw solid var(--bs-border-gr);
		border-bottom: 90vh solid transparent;
	}


	main {
		padding: 1.5rem;

		@supports(padding: max(0px)) {
			padding-left: max(1.5rem, env(safe-area-inset-left, 0));
			padding-right: max(1.5rem, env(safe-area-inset-right, 0));
		}
	}
	.container > div:first-child {
		padding-top: 40px;
	}
	.heading {
		text-align: center;
		margin-bottom: 10px;
	}
	.container .heading {
		max-width: 400px;
	}

	.form {
		box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
		--bs-bg-opacity: 1;
		background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity));
		max-width: 400px;
	}
	.form-block {
		padding: 1.5rem
	}
	.form-group:not(:last-of-type) {
		margin-bottom: 1.5rem;
	}

	.accordion-header .accordion-button {
		font-size: 0.9rem;
		line-height: 1.5rem;
		padding: 6px 12px
	}
	.accordion-body > div {
		padding: 9px 12px;
	}

	.user {
		padding: 1rem 1.5rem;
	}
	.form-user-header {
		padding: 14px 0px 7px;
	}
	.subheading {
		margin-bottom: 7px;
	}
	.avatar {
		margin-right: 1.5rem;
	}

	.btn-group .btn {
		margin-right: 0.2rem;
	}
	.form-group .btn:not(:first-of-type) {
		margin-top: 12px;
	}

	.form-check {
		margin-bottom: 1rem;
	}
}

@media (max-width: 600px) {
	.form, .heading {
		max-width: 600px;
	}
}


@media (max-width: 575px) {

	.tr-2 {
		height: 15vh;
		width: 30vw;
		border-right: 30vw solid var(--bs-border-gr);
		border-bottom: 15vh solid transparent;
	}

	.tr-3 {
		height: 10vh;
		width: 80vw;
		border-right: 80vw solid var(--bs-border-gr);
		border-bottom: 10vh solid transparent;
	}


	body {
		font-size: 1rem;
	}
	[data-bs-theme="light"] body {
		// background-color: var(--bs-body-bg);
	}
	[data-bs-theme="dark"] body {
		background-image: none;
	}

	.btn {
		margin-bottom: 0.5em;
	}
	.btn:not(#theme) {
		width: 100%;
		font-size: 1rem;
	}
	.heading {
		text-align: left;
		padding: 1rem;
	}
	.form > div:first-child:not(.form-user-header) {
		border-top: 1px solid rgb(128, 128, 128, 0.4)
	}
	/*pokud nemá formulář záhlaví s výčtem uživatelů, bude mít na mobilu horní linku pod nadpisem*/
	.heading h4 {
		margin: 0px
	}
	.form-check.form-switch {
		margin-bottom: 0px;
	}
	.form-check.form-switch label {
		line-height: 25px;
	}
	.logo {
		position: absolute;
		top: 1rem;
		left: 1rem;

		@supports(padding: max(0px)) {
			left: max(1rem, env(safe-area-inset-left));
		}
	}
	.site-footer {
		padding: 1rem;
	}
	.form-user-header {
		padding: 0px 0px 7px;
		position: relative;
	}
	.block-header::after, .block-header::before {
		border-bottom: 1px solid rgb(128, 128, 128, 0.4);
	}

	.container {
		padding-top: 112px;
	}

	#collapseSwitch {
		position: fixed;
		right: 14px;
		top: 48px;
		background-color: var(--bs-body-bg);
		margin-top: 6px;
		border: 1px solid gray;

		.form-switch.form-check {
			padding: 0.5rem 0.5rem 0.5rem 3rem;
		}
	}

	.user {
		padding: 1rem 1rem 0.875rem;
	}
	.avatar {
		margin-right: 1rem;
	}
	.form-block {
		padding: 1rem
	}
	.form-group:not(:last-of-type) {
		margin-bottom: 1rem;
	}
	.form input {
		padding: 9px;
	}
	.form button {
		padding: 12px;
	}
	.form-header {
		padding: 0.8em 0 0 0;
	}
	.form-check {
		margin-bottom: 0.5rem;
	}
	.form-check label {
		margin-left: 11px;
		line-height: 28px;
	}

	.accordion-body > div {
		padding: 0.8rem;
	}
	.btn-group .btn {
		padding: 0.5em;
	}
}
